import React, { useEffect, useCallback, useState, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingBar from "react-top-loading-bar";
import Router, { useRouter } from "next/router";
import Link from "next/link";
import Image from "next/image";
import AOS from "aos";

import logo from "../../../images/logo.png";
import Typography from "../../../higher-order-components/typography-hoc";
import Button from "../../../higher-order-components/button-hoc";
import {
  getLocal,
  removeLocal,
  setLocal,
} from "../../../common/function/storage";
import { checkLogOrNot } from "../../../common/function/function";
import Loading from "../../../higher-order-components/loading/loading";
import Icon from "../../../common/icon/icon";

const Footer = () => {
  const dispatch = useDispatch();
  const _TOKEN = getLocal("access_token");
  const _ALBUMS_TYPE = useSelector((e) => e?.memorize?.albumsTypes);
  const location = useRouter();
  const [progress, setProgress] = useState(0);
  const _GET_AUDIO_TYPES = useSelector((e) => e?.albums?.audioTypes);
  const [audioTypeState, setAudioTypeState] = useState([_GET_AUDIO_TYPES])
  const openSoundBoard = () => {
    removeLocal("website");
    setLocal("website", "website");
    window.open(process.env.NEXT_PUBLIC_SOUNDBOARD, "_self");
  };

  useEffect(()=> {
    let audioTypes = [
      {
          "audio_type_id": "5",
          "audio_type": "Music",
          "display_order": 1
      },
      {
          "audio_type_id": "2",
          "audio_type": "Soundscape",
          "display_order": 2
      },
      {
          "audio_type_id": "1",
          "audio_type": "Sound Effect",
          "display_order": 3
      },
      {
          "audio_type_id": "3",
          "audio_type": "Voiceover",
          "display_order": 4
      },
      {
          "audio_type_id": "4",
          "audio_type": "Event",
          "display_order": 5
      }
  ]
  setAudioTypeState(audioTypes)
  },[_GET_AUDIO_TYPES])


  useEffect(() => {
    setProgress(0);
  }, []);

  const redirectSocial = (passURL) => {
    window.open(passURL, "_blank");
  };

  const commonClick = useCallback(async (routerName, setLocalName) => {
    setProgress(progress + 80);
    Router.push({
      pathname: `${routerName}`,
    });
    removeLocal("active");
    removeLocal("routers");
    setLocal("routers", `${setLocalName}`);
    await window.scrollTo(0, 0);
  });
  const audioTypeClick = async (items) => {
    await dispatch({
      type: "[ACTION] GET_ALBUMS",
      payload: null,
    });
    dispatch({
      type: "ALBUMS_TYPE",
      payload: {
        label: items?.audio_type,
        value: items?.audio_type_id,
      },
    });
    
    setProgress(0);
    commonClick("/store/albums", "/store/albums");
  };

  useEffect(() => {
    AOS.init();
    AOS.refresh();
    AOS.init({
      disable: function () {
        var maxWidth = 991;
        return window.innerWidth < maxWidth;
      },
    });
  }, []);

  const homeDiv = () => {
    if (_TOKEN) {
      return (
        <span onClick={() => checkLogOrNot(_TOKEN)} className="link-text">
          Home
        </span>
      );
    } else {
      return (
        <Link href="/" className="link-text">
          Home
        </Link>
      );
    }
  };

  const faqDiv = () => {
    if (_TOKEN) {
      return (
        <Button
          className={"link-text"}
          icon={""}
          position={""}
          content={"FAQ"}
          onClick={() => {
            commonClick("/all-faq", "/all-faq");
          }}
        ></Button>
      );
    } else {
      return (
        <Link href="/#faq" className="link-text">
          FAQ
        </Link>
      );
    }
  };

  const planDiv = () => {
    if (_TOKEN) {
      return (
        <span onClick={() => checkLogOrNot(_TOKEN)} className="link-text">
          Plans
        </span>
      );
    } else {
      return (
        <Link href="/#pricing" className="link-text">
          Plans
        </Link>
      );
    }
  };

  const toolDiv = () => {
    if (_TOKEN) {
      return (
        <span onClick={() => checkLogOrNot(_TOKEN)} className="link-text">
          Tools
        </span>
      );
    } else {
      return (
        <Link href="/#tool" className="link-text">
          Tools
        </Link>
      );
    }
  };

  const checkActive = (flag) => {
    removeLocal("active");
    setLocal("active", flag);
  };

  return (
    <React.Fragment>
    {/*   {_ALBUMS_TYPE?.lable ? (
        <LoadingBar
          color="#dcba6c"
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
          height={4}
        />
      ) : null} */}
      <div className={location?.pathname === "/" ? "home_footer" : ""}>
        <footer className="footer-section">
          <div className="container">
            <div className="footer-content">
              <div className="footer-box-div">
                <div className="footer-widget">
                  <div className="footer-logo">
                    <div
                      onClick={() => {
                        checkLogOrNot(_TOKEN);
                      }}
                    >
                      <Image
                        src={logo}
                        className="img-fluid cursor-pointer"
                        alt="logo"
                        loading="eager"
                        priority
                      />
                    </div>
                  </div>
                  <div className="footer-text">
                    <Typography variant={"body1"} className={""}>
                      If you are having any issues with BattleBards, either as a
                      customer or artist, please click the Support tab and file
                      a ticket, we will get back to you as soon as possible. If
                      you're interested in joining us as an artist, please file
                      a support ticket with a link to your current work.
                    </Typography>
                  </div>
                </div>
              </div>
              <div className="footer-box-div">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <Typography variant={"h3"} className={""}>
                      Sitemap
                    </Typography>
                  </div>
                  <ul>
                    <li
                      className="cursor-pointer"
                      onClick={() => {
                        !_TOKEN ? checkActive("/") : checkActive("/");
                      }}
                    >
                      {homeDiv()}
                    </li>
                    <li className="cursor-pointer">{faqDiv()}</li>
                    {!_TOKEN ? (
                      <li
                        className="cursor-pointer"
                        onClick={() => {
                          !_TOKEN ? checkActive("pricing") : checkActive("/");
                        }}
                      >
                        {planDiv()}
                      </li>
                    ) : (
                      <li
                        className="cursor-pointer"
                        onClick={() => {
                          Router.push("/change-plan");
                        }}
                      >
                        <Button
                          className={"link-text"}
                          icon={""}
                          position={""}
                          content={"Plans"}
                          onClick={() => {
                            Router.push("/change-plan");
                          }}
                        ></Button>
                      </li>
                    )}
                    <li className="cursor-pointer">
                      <Button
                        className={"link-text"}
                        icon={""}
                        position={""}
                        content={"Privacy Policy"}
                        onClick={() => {
                          commonClick("/privacy-policy", "/privacy-policy");
                        }}
                      ></Button>
                    </li>
                    <li className="cursor-pointer">
                      <Button
                        className={"link-text"}
                        icon={""}
                        position={""}
                        content={"Explore"}
                        onClick={() => {
                          commonClick("/store/explore", "/store/explore");
                        }}
                      ></Button>
                    </li>

                    <li className="cursor-pointer">
                      <Button
                        className={"link-text"}
                        icon={""}
                        position={""}
                        content={"Terms of Use"}
                        onClick={() => {
                          commonClick("/term-of-service", "/term-of-service");
                        }}
                      ></Button>
                    </li>
                    {!_TOKEN ? (
                      <li
                        className="cursor-pointer"
                        onClick={() => {
                          !_TOKEN ? checkActive("tool") : checkActive("/");
                        }}
                      >
                        {toolDiv()}
                      </li>
                    ) : null}
                    <li className="cursor-none">
                      <span>Partners</span>
                    </li>
                    <li className="cursor-pointer">
                      <Button
                        className={"link-text"}
                        icon={""}
                        position={""}
                        content={"Contact"}
                        onClick={() => {
                          commonClick("/contact", "/contact");
                        }}
                      ></Button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="footer-box-div">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <Typography variant={"h3"} className={""}>
                      Tools
                    </Typography>
                  </div>
                  <ul>
                    <li className="w-100 cursor-pointer">
                      <span onClick={() => openSoundBoard()}>Soundboard</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="footer-box-div">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <Typography variant={"h3"} className={""}>
                      Types
                    </Typography>
                  </div>
                  <ul>
                    {audioTypeState?.map((items, index) => (
                      <li className="w-100 cursor-pointer" key={index}>
                          <Suspense fallback={<Loading />}>
                          <span
                            onClick={async () => {
                              audioTypeClick(items);
                            }}
                          >
                            {items?.audio_type}
                          </span>
                        </Suspense>
                        </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright-area">
            <div className="container">
              <div className="row flex--align-center footer-sec-div">
                <div className="col-xl-6 col-lg-6 text-center text-lg-left">
                  <div className="copyright-text text-left">
                    <Typography
                      variant={"body1"}
                      className={"color-white fs-15"}
                    >
                      Copyright &copy; {`${new Date().getFullYear()}`}{" "}
                      Battlebards
                    </Typography>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 d-none d-lg-block text-right">
                  <div className="footer-social-icon mob-m-t-10 footer-social-main-icon">
                    <span className="social-circle"
                      onClick={() =>
                        redirectSocial("https://www.instagram.com/battlebards/")
                      }
                    >
                      
                      {/* <Icon icon="instagram" /> */}
                      <span className="icon-instagram-icon footer-icon"></span>
                    </span>
                    <span className="social-circle"
                      onClick={() =>
                        redirectSocial("https://www.youtube.com/c/battlebards")
                      }
                    >
                    
                      {/* <Icon icon="youtube" /> */}
                      <span className="icon-youtube-icon footer-icon"></span>
                    </span>
                    <span className="social-circle"
                      onClick={() =>
                        redirectSocial("https://twitter.com/battlebards")
                      }
                    >
                      
                      {/* <Icon icon="twitter"/> */}
                      <span className="icon-x-twitter footer-icon"></span>
                    </span>
                    <span className="social-circle"
                      onClick={() =>
                        redirectSocial("https://www.facebook.com/BattleBards/")
                      }
                    >
                      
                      {/* <Icon icon="facebook" /> */}
                      <span className="icon-facebook-icon footer-icon"></span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </React.Fragment>
  );
};
export default Footer;
